<script setup lang="ts">
const props = defineProps<{
  label?: string;
}>();
</script>
<template>
  <main class="">
    <div class="h-[62px] w-full flex justify-between">
      <SystemLogoPrimary />
      <CommonButton
        :label="props.label"
        @onClick?=""
        rounded
        color="secondary"
      />
    </div>
    <slot />
  </main>
</template>
<style scoped></style>
